<template>
    <div class="icon-weather">
        <ul>
            <li>
                <div class="icon-weater-item">
                    <i class="">
                        <img src="./fengsu.svg" alt="">
                    </i>
                </div>
                <div class="weater-style">
                    <div>风速</div>
                    <div>{{synthesize.length && synthesize[0].windSpeedDay}}</div>
                </div>
            </li>
            <li>
                <div class="icon-weater-item">
                    <i class="">
                        <img src="./pm.svg" style="width: 100%;" alt="">
                    </i>
                </div>
                <div class="weater-style">
                    <div>湿度</div>
                    <div>{{synthesize.length && synthesize[0].humidity}}</div>
                </div>
            </li>
            <li>
                <div class="icon-weater-item">
                    <i class="">
                        <img src="./fengxiang.svg" style="width: 100%;" alt="">
                    </i>
                </div>
                <div class="weater-style">
                    <div>风向</div>
                    <div>{{synthesize.length && synthesize[0].windDirDay}}</div>
                </div>
            </li>
        </ul>
        <div class="weather-list-title">暂无预警信息</div>
    </div>
</template>
    
<script>
    export default {
        props: {
            synthesize: {
                type: Array,
                default:() => [],
            },
        },
    }
</script>

<style>
.icon-weather { width: 100%; padding: 10px 0px 0px 0px;}

.icon-weather ul {display: flex; justify-content: space-between;}
.icon-weather ul li {display: flex; width: calc((100% - 20%) / 3);min-width: 60px; height: 60px;/* border: 1px solid red; */ border-radius: 5px; background: linear-gradient(to left, rgba(24,87,214,1)/* rgba(24,64,132,1) */, rgba(24,87,214,0)); border-radius: 5px 0 0 0; transition: all 0.5s 0s; border-radius: 5px;}
.icon-weather ul li:hover {background: linear-gradient(to left, rgba(54,153,255,1)/* rgba(24,131,132,1) */, rgba(54,153,255,0));}
/* 字体图标 */
.icon-weather ul li .icon-weater-item { display: flex; justify-content: center; align-content: center; align-items:center; width: 50%; height: 100%; }
.icon-weather ul li .icon-weater-item i img {width: 100%;}


/* 图标右侧文字 */
.icon-weather ul li .weater-style {display: flex; flex-direction: column; justify-content: center; width: 50%; height: 100%; text-align: left; font-size: 12px;}
.icon-weather ul li .weater-style div {font-size: 12px;}

.icon-weather .weather-list-title {width: 100%; height: 30px; line-height: 30px; text-align: center;/* border: 1px solid red; */ margin-top: 10px; background-color: #123469;}
</style>
