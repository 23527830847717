<template>
    <div class="com-show-weather">
        <icon-weather :synthesize="synthesizeWeather"></icon-weather>
        <zj-scroll-list identity="zWeatherForecast" :dataList="tableWeathers" :limit="3" :loop="false"></zj-scroll-list>
    </div>
</template>

<script>
import ZjScrollList from 'zj-scroll-list'
import IconWeather from '@/components/zjson-weather/icon-weather.vue'
import ajax from '@/ajax.js'
export default {
    name: 'ComWeather',
    components: { 
        ZjScrollList,
        IconWeather,
    },
    data(){
        return {
            tableWeathers: [
                {
                    "key": "key1",
                    "row": [
                        { "text": "2020.12.16 今天 ", },
                        { 
                            "icon": require("@/static/icons/icon-share.svg"), 
                            "icon_color": "blue",
                            "text": "多云", },
                        { "text": "7℃/0℃", },
                    ]
                },
            ],
            getWeathers: {},
            synthesizeWeather: [],
        }
    },
	computed:{
		windowInfo(){
			return this.$store.state.modWindows['comWeather'];
		},
		// weathers(){
		// 	return this.$store.state.modWeathers;
		// },
	},
	created(){
		// this.update()
	},
    mounted(){
        this.download();
    },
	methods:{
        // 下载天气预报数据
        download(){
            ajax({
                url: 'https://devapi.qweather.com/v7/weather/3d?location=101300101&key=2b2a47fba7d24ed2a70e0c06444f6755',
            }).then(res => {
                // console.log("res", res);
                this.getWeathers = res;
                // console.log("this.getWeathers1", this.getWeathers)
                this.update();
            }).catch(err =>{
                // console.log("错误", err);
            })
        },
        // 将数据处理更新到列表中
		update(){
			this.tableWeathers = this.getWeathers.daily.map( it => {
                // 获取当天上午0点的时间戳 
                // var currentTime = (new Date()).toLocaleDateString()
                // currentTime = currentTime.replace(/\//g,'-');
                // var nowdate = (new Date(currentTime))/1000;
                // var fxDate = it.fxDate.replace(/\//g,'-');
                // var fxDateTimestamp = (new Date(fxDate))/1000;
                // var result = fxDateTimestamp - nowdate;
                // var consult = result/86400;
                
                // 时间戳
                var consult = ~~(((new Date(it.fxDate.replace(/\//g,'-')))/1000 - (new Date((new Date()).toLocaleDateString().replace(/\//g,'-')))/1000)/86400);
				return {
					"key": "key1",
					"row": [
					    { "text": it.fxDate },
                        { 
                            "text": (
                                consult == 0 ? "今天" :
                                consult == 1 ? "明天" :
                                consult == 2 ? "后天" : "？？"
                        )},
					    {
					        "icon": (
                                it.textDay === '阴' ? require("@/static/icons/weather/icon-weather-cloudy.svg") :
                                it.textDay === '晴' ? require("@/static/icons/weather/icon-weather-fine.svg") :
                                it.textDay === '多云' ? require("@/static/icons/weather/icon-weather-fine-cloudy.svg") :
                                it.textDay === '雾' ? require("@/static/icons/weather/icon-weather-fog.svg") :
                                it.textDay === '霾' ? require("@/static/icons/weather/icon-weather-fog-haze.svg") :
                                it.textDay === '雷阵雨伴有冰雹' ? require("@/static/icons/weather/icon-weather-hail.svg") :
                                it.textDay === '大雨' ? require("@/static/icons/weather/icon-weather-heavy-rain.svg") :
                                it.textDay === '小雨' ? require("@/static/icons/weather/icon-weather-light-rain.svg") :
                                it.textDay === '小雪' ? require("@/static/icons/weather/icon-weather-light-snow.svg") :
                                it.textDay === '中雨' ? require("@/static/icons/weather/icon-weather-moderate-rain.svg") :
                                it.textDay === '中雪' ? require("@/static/icons/weather/icon-weather-moderate-snow.svg") :
                                it.textDay === '雨夹雪' ? require("@/static/icons/weather/icon-weather-rain-snow.svg") :
                                it.textDay === '大雪' ? require("@/static/icons/weather/icon-weather-snow.svg") :
                                // it.textDay === '雷阵雨' ? require("@/static/icons/weather/icon-weather-thunder.svg") :
                                it.textDay === '雷阵雨' ? require("@/static/icons/weather/icon-weather-thunder-rian.svg") :
								require("@/static/icons/weather/icon-weather-heavy-rain.svg")
							), 
					        "icon_color": "blue",
					        "text": it.textDay },
					    { "text": it.tempMin+"/"+it.tempMax+"℃"},
					]
				}
			});
            // console.log("111", "11");
			const today = this.getWeathers.daily.map(it => {
                return {
                    windSpeedDay: it.windSpeedDay+"级",
                    windDirDay: it.windDirDay,
                    // 湿度
                    humidity: it.humidity,
                }
            });
            // console.log("today", "ddd");
            this.synthesizeWeather = today;
            // console.log("this.synthesizeWeather", this.synthesizeWeather);
		},
	}
}
</script>